import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EBuildingCategories } from '../schema/IBuildingSchema'
import {
  IProductionData,
  IProductionMachine,
  IProductionRecipe,
  IRecipeCount,
} from '../types/ProductionTypes'
import data from '../types/Data'

const excludedBuildings: string[] = ['oil-extractor', 'water-extractor']

export interface SliceState {
  allMachines: IProductionMachine[]
  selectedRecipes: IRecipeCount[]
}

const initialState: SliceState = { allMachines: [], selectedRecipes: [] }

const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
    load: (state: SliceState, action: PayloadAction<string>) => {
      const prodData: IProductionData = {
        machines: [],
      }

      data
        .getBuildings()
        .filter(
          (building) => building.categories.find(
            (category) => category in EBuildingCategories,
          ) != null && excludedBuildings.indexOf(building.slug) === -1,
        )
        .forEach((building) => {
          prodData.machines.push({
            slug: building.slug,
            name: building.name,
            className: building.className,
            recipes: data
              .getRecipes(building)
              .filter(
                (recipe) => recipe.products
                  .some((product) => product.item.toLowerCase().indexOf(action.payload) > -1)
                  || recipe.name.toLowerCase().indexOf(action.payload) > -1,
              )
              .map((recipe) => ({
                name: recipe.name,
                slug: recipe.slug,
                className: recipe.className,
                products: data.getProducts(recipe).map((product) => ({
                  name: product.name,
                  slug: product.slug,
                  className: product.className,
                })),
              })),
          })
        })
      state.allMachines.splice(0, state.allMachines.length)
      prodData.machines.forEach((machine) => {
        state.allMachines.push(machine)
      })
    },
    addRecipe: (
      state: SliceState,
      action: PayloadAction<IProductionRecipe>,
    ) => {
      const recipe = state.selectedRecipes.find(
        (rec) => rec.recipe.slug === action.payload.slug,
      )
      if (recipe === null || recipe === undefined) {
        state.selectedRecipes.push({ recipe: action.payload, count: 1 });
      } else {
        recipe.count += 1;
      }
    },
    decrementRecipe: (
      state: SliceState,
      action: PayloadAction<IProductionRecipe>,
    ) => {
      const index = state.selectedRecipes.findIndex(
        (recipe) => recipe.recipe.slug === action.payload.slug,
      )
      if (index === -1) return;
      const recipe = state.selectedRecipes[index]
      if (recipe.count > 1) {
        recipe.count -= 1;
      } else {
        state.selectedRecipes.splice(index, 1);
      }
    },
    clear: (state: SliceState) => {
      state.selectedRecipes.splice(0, state.selectedRecipes.length);
    },
  },
})

export const { reducer, actions } = productionSlice
