import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { IRecipeCount } from '../types/ProductionTypes'
import { RootState } from '../types/store'
import data from '../types/Data'

interface IProductionProps {
  isInput?: boolean
}

export const ProductionItems: FunctionComponent<IProductionProps> = (
  props: IProductionProps,
) => {
  const recipes: IRecipeCount[] = useSelector(
    (state: RootState) => state.production.selectedRecipes,
  )
  const inputs = data.calculateInputOutput(recipes)
  const { isInput } = props
  return (
    <List>
      {Array.from(inputs.keys()).map((key) => {
        const value: number = inputs.get(key) as number
        return (
          (isInput ? value > 0 : value < 0) && (
            <ListItem key={key.slug}>
              <ListItemAvatar>
                <Avatar
                  src={`./items/${key.slug}_64.png`}
                  alt={key.name}
                  key={`production-input-${key.slug}`}
                />
              </ListItemAvatar>
              <ListItemText primary={key.name} />
              <ListItemSecondaryAction>
                <Chip color="primary" label={`${Math.abs(value)}/min`} />
              </ListItemSecondaryAction>
            </ListItem>
          )
        )
      })}
    </List>
  )
}

ProductionItems.defaultProps = {
  isInput: false,
}

export default ProductionItems
