import {
  Action, ThunkAction, configureStore, getDefaultMiddleware, EnhancedStore,
} from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import {
  FLUSH, PAUSE, PERSIST, persistCombineReducers, PURGE, REGISTER, REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { reducer as productionReducer } from '../reducers/ProductionReducer'
import { reducer as applicationReducer } from '../reducers/ApplicationReducer'

const middlewares = [...getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})];
if (process.env.NODE_ENV === 'development') middlewares.push(logger);

const persistedReducer = persistCombineReducers({
  key: 'root',
  storage,
}, {
  application: applicationReducer,
  production: productionReducer,
});

export const getStore = (): EnhancedStore => configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
})

const store = getStore();
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
