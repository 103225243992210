import { IRecipeCount, IRecipeData } from './ProductionTypes'
import { IBuildingSchema } from '../schema/IBuildingSchema'
import { IGeneratorSchema } from '../schema/IGeneratorSchema'
import { IItemSchema } from '../schema/IItemSchema'
import { IJsonSchema } from '../schema/IJsonSchema'
import { IMinerSchema } from '../schema/IMinerSchema'
import { IRecipeSchema } from '../schema/IRecipeSchema'
import { IResourceSchema } from '../schema/IResourceSchema'
import { ISchematicSchema } from '../schema/ISchematicSchema'
import dataJson from './dataJson.json'

class Data implements IJsonSchema {
  items: { [key: string]: IItemSchema }

  recipes: { [key: string]: IRecipeSchema }

  schematics?: { [key: string]: ISchematicSchema }

  generators?: { [key: string]: IGeneratorSchema }

  resources?: { [key: string]: IResourceSchema }

  miners?: { [key: string]: IMinerSchema }

  buildings: { [key: string]: IBuildingSchema }

  constructor() {
    const data: IJsonSchema = dataJson as IJsonSchema
    this.items = data.items
    this.recipes = data.recipes
    this.schematics = data.schematics
    this.generators = data.generators
    this.resources = data.resources
    this.miners = data.miners
    this.buildings = data.buildings
  }

  getBuildings(): IBuildingSchema[] {
    return Object.values(this.buildings)
  }

  getRecipes(building: IBuildingSchema): IRecipeSchema[] {
    return Object.values(this.recipes)
      .filter((recipe) => recipe.producedIn.find((machine) => machine === building.className))
  }

  getProducts(recipe: IRecipeSchema): IItemSchema[] {
    return recipe.products
      .map((product) => Object.values(this.items).find(
        (item) => item.className === product.item,
      ))
      .filter((item): item is IItemSchema => item !== undefined)
  }

  getRecipe(name: string): IRecipeData {
    const recipe: IRecipeSchema = this.recipes[name]
    return {
      name: recipe.name,
      className: recipe.className,
      slug: recipe.slug,
      inputs: recipe.ingredients.map((ingredient) => ({
        product: this.items[ingredient.item],
        count: ingredient.amount,
      })),
      outputs: recipe.products.map((product) => ({
        product: this.items[product.item],
        count: product.amount,
      })),
      time: recipe.time,
    }
  }

  calculateInputOutput(recipes: IRecipeCount[]): Map<IItemSchema, number> {
    const inputs: Map<IItemSchema, number> = new Map()
    recipes.forEach((recipe) => {
      const recipeData = this.getRecipe(recipe.recipe.className)
      recipeData.inputs.forEach((input) => {
        const count = (60 / recipeData.time) * input.count * recipe.count
        inputs.set(input.product, (inputs.get(input.product) || 0) + count)
      })

      recipeData.outputs.forEach((output) => {
        const count = (60 / recipeData.time) * output.count * recipe.count
        inputs.set(output.product, (inputs.get(output.product) || 0) - count)
      })
    })
    return inputs
  }

  calculateTotalMachines(recipes: IRecipeCount[]) {
    const machines: Map<IBuildingSchema, number> = new Map()
    recipes.forEach((recipe) => {
      const buildingName = this.recipes[recipe.recipe.className].producedIn[0]
      const building = this.buildings[buildingName]
      machines.set(building, (machines.get(building) || 0) + recipe.count)
    })
    return machines
  }
}

export default new Data()
