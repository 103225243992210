/* eslint-disable camelcase */
import {
  IBuildingMetadataSchema,
  IManufacturerMetadataSchema,
} from './IBuildingMetadataSchema'

export enum EBuildingCategories {
  SC_Manufacturers_C = 'SC_Manufacturers_C',
  SC_Smelters_C = 'SC_Smelters_C',
  SC_Miners_C = 'SC_Miners_C',
  SC_OilProduction_C = 'SC_OilProduction_C',
}

export interface IBuildingSchema {
  slug: string
  name: string
  description: string
  className: string
  categories: EBuildingCategories[]
  buildMenuPriority: number
  metadata: IBuildingMetadataSchema
}

export interface IManufacturerSchema extends IBuildingSchema {
  metadata: IManufacturerMetadataSchema
}
