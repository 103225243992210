import React, { ChangeEvent, FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Collapse,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  InputAdornment,
  ListItemAvatar,
  Avatar,
  Tooltip,
} from '@material-ui/core'
import { ExpandLess, ExpandMore, Search } from '@material-ui/icons'
import { RootState } from '../types/store'
import { IProductionMachine, IProductionRecipe } from '../types/ProductionTypes'
import { actions as productionActions } from '../reducers/ProductionReducer'
import useStyles from '../types/style'

const Recipe: FunctionComponent<IProductionRecipe> = (recipe) => {
  const dispatch = useDispatch()
  const { addRecipe } = productionActions
  return (
    <Tooltip title="Click to add this recipe" aria-label="Click to add this recipe">
      <ListItem button onClick={() => dispatch(addRecipe(recipe))}>
        {recipe.products.map((product) => (
          <ListItemAvatar key={`recipe-list-${product.slug}`}>
            <Avatar
              src={`./items/${product.slug}_64.png`}
              alt={recipe.name}
            />
          </ListItemAvatar>
        ))}
        <ListItemText primary={recipe.name} />
      </ListItem>
    </Tooltip>
  )
}

const Machine: FunctionComponent<IProductionMachine> = (machine) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <Avatar src={`./items/${machine.slug}_64.png`} alt={machine.name} />
        </ListItemAvatar>
        <ListItemText primary={machine.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.nestedList}>
          {machine.recipes.map((recipe) => (
            <Recipe {...recipe} key={`recipe-list-${recipe.slug}`} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export const RecipeList: FunctionComponent = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const machines: IProductionMachine[] = useSelector(
    (state: RootState) => state.production.allMachines,
  )
  const { load } = productionActions
  if (machines.length === 0) dispatch(load(''))
  return (
    <>
      <Paper className={classes.search}>
        <InputBase
          role="search"
          className={classes.input}
          placeholder="Search Recipe"
          inputProps={{ 'aria-label': 'search recipe' }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => dispatch(load(evt.target.value))}
          endAdornment={(<InputAdornment position="end" className={classes.iconButton}><Search /></InputAdornment>)}
        />
      </Paper>
      <List className={classes.rootList}>
        {machines
          .filter((machine) => machine.recipes.length > 0)
          .map((machine) => (
            <Machine {...machine} key={`recipe-list-${machine.slug}`} />
          ))}
      </List>
    </>
  )
}

export default RecipeList
