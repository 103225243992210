import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppBar,
  Container,
  createMuiTheme,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Switch,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {
  Brightness3, Brightness5, ChevronLeft, Menu,
} from '@material-ui/icons'
import clsx from 'clsx'
import { RootState } from '../types/store'
import { actions } from '../reducers/ApplicationReducer'
import { Production } from './Production'
import { RecipeList } from './RecipesList'
import { WorkerNotification } from './WorkerNotification'
import useStyles from '../types/style'

const App: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.application.darkMode);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.toggle(event.target.checked));
  };

  const theme = createMuiTheme({
    overrides: {
      MuiAppBar: {
        colorPrimary: darkMode ? { backgroundColor: '#424242' } : undefined,
      },
    },
    palette: {
      type: darkMode ? 'dark' : 'light',
    },
  });

  const classes = useStyles()
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              role="button"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dashboard
            </Typography>
            <Brightness5 />
            <Switch
              checked={darkMode}
              onChange={handleChange}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Brightness3 />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={() => setOpen(false)} aria-label="close drawer">
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <RecipeList />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Production />
          </Container>
        </main>
        <WorkerNotification />
      </div>
    </ThemeProvider>
  )
}

export default App
