import React, { FunctionComponent } from 'react'
import {
  Divider, Grid, Paper, Typography,
} from '@material-ui/core'
import { SelectedRecipes } from './SelectedRecipes'
import useStyles from '../types/style'
import { ProductionItems } from './ProductionItems'
import { FactoriesNeeded } from './FactoriesNeeded'

export const Production: FunctionComponent = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" gutterBottom>Selected Recipes</Typography>
          <Divider />
          <SelectedRecipes />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" gutterBottom>Factories needed</Typography>
          <Divider />
          <FactoriesNeeded />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" gutterBottom>Production Inputs</Typography>
          <Divider />
          <ProductionItems isInput />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" gutterBottom>Production Outputs</Typography>
          <Divider />
          <ProductionItems />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Production
