import {
  Avatar,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../types/store'
import data from '../types/Data'
import { IRecipeCount } from '../types/ProductionTypes'

export const FactoriesNeeded: FunctionComponent = () => {
  const recipes: IRecipeCount[] = useSelector(
    (state: RootState) => state.production.selectedRecipes,
  )
  const factories = data.calculateTotalMachines(recipes)
  let power = 0;
  return (
    <>
      <List>
        {Array.from(factories.keys()).map((key) => {
          const value: number = factories.get(key) as number
          power += (key.metadata.powerConsumption as number) * value
          return (
            <ListItem key={key.slug}>
              <ListItemAvatar>
                <Avatar
                  src={`./items/${key.slug}_64.png`}
                  alt={key.name}
                  key={`production-input-${key.slug}`}
                />
              </ListItemAvatar>
              <ListItemText primary={key.name} />
              <ListItemSecondaryAction>
                <Chip color="primary" label={value} />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <Divider />
      <Typography align="right">{`Power consumption: ${power} Mw`}</Typography>
    </>
  )
}

export default FactoriesNeeded
