import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ApplicationState {
    darkMode: boolean
}

const initialState: ApplicationState = {
  darkMode: false,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    toggle: (state: ApplicationState, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
  },
});

export const { reducer, actions } = applicationSlice;
