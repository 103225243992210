import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  withStyles,
  Theme,
  fade,
  useTheme,
  Fab,
} from '@material-ui/core'
import { RemoveCircle, AddCircle, Delete } from '@material-ui/icons'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRecipeCount } from '../types/ProductionTypes'
import { RootState } from '../types/store'
import { actions as productionActions } from '../reducers/ProductionReducer'

interface IColorIconButtonProps {
  base: PaletteColor
}

const ColorIconButton = withStyles((theme: Theme) => ({
  root: (props: IColorIconButtonProps) => ({
    marginLeft: 5,
    marginRight: 5,
    color: props.base.main,
    '&:hover': {
      backgroundColor: fade(props.base.main, theme.palette.action.hoverOpacity),
    },
  }),
}))(IconButton);

export const SelectedRecipes: FunctionComponent = () => {
  const recipes: IRecipeCount[] = useSelector(
    (state: RootState) => state.production.selectedRecipes,
  )
  const { decrementRecipe, addRecipe, clear } = productionActions
  const dispatch = useDispatch()
  const theme = useTheme();
  return (
    <>
      <List>
        {recipes.map((recipe) => (
          <ListItem
            key={`selected-recipe-list-${recipe.recipe.slug}`}
          >
            {recipe.recipe.products.map((product) => (
              <ListItemAvatar key={`selected-recipe-list-avatar-${product.slug}`}>
                <Avatar
                  src={`./items/${product.slug}_64.png`}
                  alt={product.name}
                />
              </ListItemAvatar>
            ))}
            <ListItemText primary={recipe.recipe.name} />
            <ListItemSecondaryAction>
              <ColorIconButton base={theme.palette.error} aria-label="remove" onClick={() => dispatch(decrementRecipe(recipe.recipe))}>
                <RemoveCircle />
              </ColorIconButton>
              <Chip role="status" color="primary" label={recipe.count} />
              <ColorIconButton base={theme.palette.success} aria-label="add" onClick={() => dispatch(addRecipe(recipe.recipe))}>
                <AddCircle />
              </ColorIconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Fab
        disabled={recipes.length === 0}
        onClick={() => dispatch(clear())}
        variant="extended"
      >
        Clear
        <Delete />
      </Fab>
    </>
  )
}

export default SelectedRecipes
