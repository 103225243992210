import React, { FC, useEffect } from 'react'
import { Snackbar, Button } from '@material-ui/core'
import * as serviceWorker from '../serviceWorkerRegistration'

export const WorkerNotification: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSWSuccess = () => {
    setShowSuccess(true);
  }

  const onSWError = (error: string) => {
    setShowError(true);
    setErrorMessage(error);
  }

  useEffect(() => {
    serviceWorker.register({
      onSuccess: onSWSuccess,
      onUpdate: onSWUpdate,
      onError: onSWError,
    });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <>
      <Snackbar
        open={showReload}
        message="A new version is available!"
        onClick={reloadPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={(
          <Button
            color="inherit"
            size="small"
            onClick={reloadPage}
          >
            Reload
          </Button>
        )}
      />

      <Snackbar
        open={showSuccess}
        message="Content is cached for offline use."
        onClick={() => { setShowSuccess(false) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      <Snackbar
        open={showError}
        message={errorMessage}
        onClick={() => { setShowError(false) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </>
  );
}

export default WorkerNotification;
